<template>
  <div ref="echartBox" style="width: 100%; height: 400px"></div>
</template>

<script>
export default {
  mounted() {
    var myChart = window.echarts.init(this.$refs.echartBox);
    // 指定图表的配置项和数据
    var option = {
      tooltip: {},
      // legend: {
      //   data: ["销量"]
      // },
      xAxis: {
        data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
      },
      yAxis: {},
      series: [
        {
          name: '销量',
          type: 'bar',
          data: [5, 20, 36, 10, 10, 20]
        }
      ]
    };
    myChart.setOption(option);
  }
};
</script>

<style></style>
